import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(VueCurrencyFilter,
  {
    name: 'withPence',
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  }, {
    name: 'withoutPence',
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  })
