export default {
  state: () => ({
    requests: {},
  }),

  actions: {
    addRequest({commit}, modalName) {
      commit('ADD_REQUEST', modalName);
      $nuxt.$emit('openModal-' + modalName);
    },
    async checkRequest({ state, commit }, {name, callback}) {
      const exists = state.requests[name] === true;

      if (exists === false) {
        return;
      }

      await commit('DELETE_REQUEST', name);

      callback();
    }
  },

  mutations: {
    ADD_REQUEST(state, modalName) {
      state.requests[modalName] = true;
    },
    DELETE_REQUEST(state, modalName) {
      delete state.requests[modalName];
    },
  },

  namespaced: true,
}
