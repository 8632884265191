// INIT: 1,
// IDLE: 2,
// PROCESSING_QUEUED: 9,
// PROCESSING: 3,
// PROCESSING_COMPLETE: 5,
// PROCESSING_ERROR: 6,
// PROCESSING_REVERT_ERROR: 10,
// LOADING: 7,
// LOAD_ERROR: 8

export const statuses = [1, 2, 9, 3, 5, 6, 10, 7, 8]
export const processingStatuses = [1, 2, 3, 7, 9]
export const processedStatuses = [5, 6, 8, 10]
