export default {
  state: () => ({
    appLoading: true,
  }),

  actions: {
    setAppLoading({ commit }, loading) {
      commit('SET_APP_LOADING', loading);
    },
  },

  mutations: {
    SET_APP_LOADING(state, loading) {
      state.appLoading = loading;
    },
  },
}
